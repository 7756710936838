import React, { useRef, useEffect } from 'react';

const delay = 1000 / 60; // ~60fps for scrolling updates
const MAX_SPEED = 30; // Maximum scrolling speed
const DECAY = 0.9; // Deceleration factor for smooth stopping

function Slider(props) {
  const { className = '' } = props;
  const containerRef = useRef(null);
  const x = useRef(0);
  const lastTouchTime = useRef(0);
  const isTouching = useRef(false);
  const interval = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleOnMouseEnter = () => {
    clearInterval(interval.current);
    if (!isTouching.current) {
      interval.current = setInterval(() => {
        containerRef.current?.scrollBy(x.current, 0);
      }, delay);
    }
  };

  const handleOnMouseMove = (e) => {
    if (!containerRef.current) return;

    const bBox = containerRef.current.getBoundingClientRect();
    if (Date.now() - lastTouchTime.current > 500) {
      isTouching.current = false;
    }

    const { clientX } = e;
    const containerWidth = bBox.width;
    const halfWidth = containerWidth / 2;
    let currentX = (clientX - bBox.left - halfWidth) / halfWidth;

    // Exponential adjustment for speed based on cursor position
    currentX = (Math.abs(currentX) / 2) * (currentX > 0 ? 1 : -1);
    x.current = Math.sin(currentX * Math.PI) * (containerWidth / 1024) * MAX_SPEED;
  };

  const handleOnMouseLeave = () => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      x.current *= DECAY;
      containerRef.current?.scrollBy(x.current, 0);
      if (Math.abs(x.current) < 0.1) {
        clearInterval(interval.current);
      }
    }, delay);
  };

  const handleOnTouchStart = () => {
    lastTouchTime.current = Date.now();
    isTouching.current = true;
  };

  return (
    <div
      ref={containerRef}
      className={`slider is-flex ${className}`}
      onMouseEnter={handleOnMouseEnter}
      onMouseMove={handleOnMouseMove}
      onMouseLeave={handleOnMouseLeave}
      onTouchStart={handleOnTouchStart}
    >
      {props.children}
    </div>
  );
}

export default Slider;
